.job-posting-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .job-posting-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .job-posting-container input,
  .job-posting-container textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
  }
  
  .job-posting-container button {
    padding: 10px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .job-posting-container button:hover {
    background-color: #4cae4c;
  }
  