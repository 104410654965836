.dashboard{
    width: 100vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dashboard form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}.job-dashboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-details span {
    font-size: 18px;
    color: #333;
  }
  
  .user-details button {
    padding: 10px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .user-details button:hover {
    background-color: #c9302c;
  }
  
  .job-dashboard-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .job-dashboard-container ul {
    list-style: none;
    padding: 0;
  }
  
  .job-dashboard-container li {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .job-dashboard-container li h3 {
    margin: 0 0 10px 0;
    color: #333;
  }
  
  .job-dashboard-container li p {
    margin: 5px 0;
    color: #666;
  }
  